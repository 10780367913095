<template>
  
  <Animation3 />

 
  
  <div id="privacy-policy" class="page">
    <div class="sec-1">
      <div class="container px-4">
        <h1 class="py-24">{{content.PageTitle}}</h1>
        <div class="py-8">
          <DoubleDownArrow class="dark" />
          <br />
          <!-- <router-link to="/" class="text-white font-bold"> -->
            <span class="pt-4">{{content.BannerButtonText}}</span>
          <!-- </router-link> -->
        </div>
      </div>
    </div>

    <!-- <div class="padding-break-rl accent"></div> -->

  <div class="container">

    <a-row class="sec-2 container py-20 px-4">
      <a-col :sm="{ span: 20 }" :md="{ span: 18 }" :lg="{ span: 18 }">
        <p>
          {{content.CompanyName}}
          <br>
           {{content.Address1}}
          <br>
         {{content.Address2}}
       

        </p>

        <p>  
          {{content.PhoneTitle}}: <a :href="content.PhoneLink">  {{content.Phone}}</a>
        </p>
          
          <p>
          {{content.EmailTitle}}: <a style="text-decoration:none;" :href="content.EmailLink">  {{content.Email}}</a>
           </p>


              <p>

          {{content.CEOTitle}}:
          {{content.CEOName}}

              </p>
          
             <p>

          {{content.RegisteredTypeTitle}}:
            {{content.RegisteredType}}
            
              </p>
          
             <p>
          {{content.MoreInfoTitle}}:
          {{content.MoreInfo}}
         
         
              </p>
         
             <p>

          {{content.Paragraph1}}
             </p>
               <p>
         
      {{content.Paragraph2}}   

         </p>
            
      
        <div class="py-4">
          <!-- <Button name="Let's Chat About Your Project" @click="$router.push('impressum')" /> -->
        </div>
      </a-col>
    </a-row>
    <!-- <div class="section about">
      <div class="container">
        <h3 class="custom-heading">WHAT WE'RE UP phone numberTO</h3>
        <div class="flex">
          <blockquote class="quote">Gap Dynamics is typing...</blockquote>
          <div class="summary">
            <p>Gap Dynamics is typing...</p>
            <p>Gap Dynamics is typing...</p>
          </div>
        </div>
      </div>
    </div>
    <section class="details container">
      <div>
        <h3>ROLES AND RESPONSIBILITIES</h3>
        <ul class="customlist">
          <li class="list__item">
            Your design skills and talent will be focused primarily on iOS or
            Android native application design
          </li>
        </ul>
        <h3>THE IDEAL CANDIDATE</h3>
        <ul class="customlist">
          <li class="list__item">
            Your design skills and talent will be focused primarily on iOS or
            Android native application design
          </li>
        </ul>
        <h3>PERKS AND BENEFITS</h3>
        <div class="perks-benefits">
          <div class="perk">
            <WebSvg />
            <p>12 paid sick or personal days per year</p>
          </div>
          <div class="perk">
            <WebSvg />
            <p>12 paid sick or personal days per year</p>
          </div>
          <div class="perk">
            <WebSvg />
            <p>12 paid sick or personal days per year</p>
          </div>
          <div class="perk">
            <WebSvg />
            <p>12 paid sick or personal days per year</p>
          </div>
        </div>
      </div>
      <div>
        <div class="apply">
          <h3>Got What it Takes?</h3>
          <p>
            Want to join the team in GER? Submit your application -- we respond
            to most inquiries within three business days.
          </p>
          <Button name="Apply Now" />
        </div>
        <h3>Looking for something similar?</h3>
        <router-link :to="{ name: 'Jobs' }">
          <button class="btn btn-alternate underline">View All Jobs</button>
        </router-link>
      </div>
    </section>-->
  </div>
 </div>
 
</template>

<script>
// import Button from "@/components/Button";
// import WebSvg from "@/assets/icons/web.svg";
import DoubleDownArrow from "@/assets/icons/double_down_arrow.svg";

 

import Animation3 from "@/components/Animation3";

import "./style.scss";
import { LanguageStrings } from "@/languages";


export default {
  components: {
    DoubleDownArrow,
 
    Animation3
    // Button,
    // WebSvg,
  },
  created() {
 


      localStorage.getItem("siteLanguage") === "GR" 
      ? (this.content = LanguageStrings.gr.PrivacyPolicyPage)
      : (this.content = LanguageStrings.en.PrivacyPolicyPage);

      document.querySelector("head title").innerText = this.content.MetaTitle;



  },
    mounted () {
    window.scrollTo(0, 0)
  },
};
</script>
